
import { Component, Vue } from 'vue-property-decorator';
import Landing from './Landing.vue';
// Component.registerHooks([
//   "beforeRouteLeave"
// ])
@Component({
  components: {
    Landing
  }
})
export default class LoginPage extends Vue {
  visible = true;
  private mobile = '';
  private code = '';
  private last_time_sent = 0;
  private verification_sent = false;
  private interval: number|null = null;
  private current_time = '2:00';
  private loading = false;
  private next = '/profile';
  seq () {
    const elapsed = 120+this.last_time_sent-(new Date().getTime()/1000);
    if (elapsed<0&&this.interval) {
      clearInterval(this.interval);
      this.interval = null;
      this.current_time = '';
      return;
    }
    const min = Math.floor(elapsed/60);
    const sec = Math.floor(elapsed%60);
    this.current_time = `${min}:${sec>9?sec:'0'+sec}`;
  }

  async sendVerificationCode(event: Event) {
    event?.preventDefault()
    this.loading = true;
    this.correctMobile()
    try {
      await this.$tayeh.getPassword('98'+this.mobile);
      this.verification_sent = true;
      this.last_time_sent = new Date().getTime()/1000;
      this.current_time = '2:00';
      this.interval = setInterval(this.seq, 500);
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  correctMobile () {
    if (!this.mobile) return;
      this.mobile = this.mobile.trim();
      this.mobile = this.mobile.replace(/۰/g, '0');
      this.mobile = this.mobile.replace(/۱/g, '1');
      this.mobile = this.mobile.replace(/۲/g, '2');
      this.mobile = this.mobile.replace(/۳/g, '3');
      this.mobile = this.mobile.replace(/۴/g, '4');
      this.mobile = this.mobile.replace(/۵/g, '5');
      this.mobile = this.mobile.replace(/۶/g, '6');
      this.mobile = this.mobile.replace(/۷/g, '7');
      this.mobile = this.mobile.replace(/۸/g, '8');
      this.mobile = this.mobile.replace(/۹/g, '9');
      this.mobile = this.mobile.replace(/\D/g, '');
  }

  async resendVerificationCode() {
    if (this.interval!==null) return;
    this.loading = true;
    this.correctMobile()
    try {
      await this.$tayeh.getPassword('98'+this.mobile);
      this.verification_sent = true;
      this.last_time_sent = new Date().getTime()/1000;
      this.current_time = '2:00';
      this.interval = setInterval(this.seq, 500);
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  async login (event: Event) {
    event?.preventDefault()
    this.loading = true;
    try {
      const res = await this.$tayeh.customerVerify('98'+this.mobile, this.code);
      this.$store.commit("setCustomerToken", res.data.access_token);
      this.$tayeh.set_token(res.data.access_token);
      this.$nextTick(() => {
        this.beforeClose();
        setTimeout(() => {
          this.$router.replace(this.next||'/profile');
        }, 200);
      })
    }catch (err) {
      this.loading = false;
    }
  }

  created() {
    this.next = this.$route.query.next as string||this.next;
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('modal');
  }
  destroyed() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal');
  }

  beforeRouteLeave (to: string, from: string, next: any) {
    this.beforeClose()
    this.$nextTick(() => {
      next()
    })
  }

  beforeClose() {
    this.visible = false;
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('modal');
  }

  handleClose() {
    this.beforeClose()
    this.$nextTick(() => {
      this.$router.replace('/');
    })
  }
}
